<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher()

    export let data
    const { content } = data
    
    async function handleAction(e, action) {
        await data.handler(e, action)
        dispatch('closemodal')
    }
</script>

<div class="modal-body">
    {#if content && content.length}
        <div class="list-group">
            {#each content as action}
                <a class="list-group-item list-group-item-action" href={`#${action.name}`} on:click|preventDefault={e => handleAction(e, action)}>{action.text}</a>
            {/each}
        </div>
    {/if}
</div>
